<template>
    <div class="widget_showcase_embed_code">
        <header class="widget_embed_code__header">
            <label class="widget_embed_code__title">
                {{ $t("cp__widgets_preview__embed_code") }}
            </label>
            <button class="widget_embed_code__close_button" @click="$emit('close')">
                <font-awesome-icon :icon="['far', 'times']" />
            </button>
        </header>
        <div class="widget_embed_code__code_holder">
            <span :id="id" class="widget_embed_code__code" ref="widget_embed_code__code">
                {{ embedCode }}
            </span>
        </div>
    </div>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTimes);

export default {
    components: {
        FontAwesomeIcon,
    },
    props: {
        embedCode: {
            type: String,
        },
        id: {
            type: String,
            default: "widget",
        },
        autoSelect: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        // eslint-disable-next-line func-names
        this.$nextTick(function () {
            if (this.autoSelect) {
                this.selectEmbedCode();
            }
        });
    },

    methods: {
        selectEmbedCode() {
            const selection = window.getSelection();
            selection.removeAllRanges();
            const range = document.createRange();
            range.selectNodeContents(this.$refs.widget_embed_code__code);
            selection.addRange(range);
        },
    },

    watch: {
        embedCode: "selectEmbedCode",
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
@import "~include-media";

.widget_showcase_embed_code {
    border-radius: 4px;
    border: 1px solid $grey_alabaster;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    overflow: hidden;
}

.widget_embed_code__header {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    height: 48px;
    padding: 0 0 0 24px;
    background-color: $blue;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget_embed_code__title {
    @extend %subtitle2_style;
    color: $white;
}

.widget_embed_code__close_button {
    margin-right: 24px;
    border: 0px;
    background-color: transparent;
    font-size: 14px;
    color: $white;
    cursor: pointer;
}

.widget_embed_code__code_holder {
    position: relative;
    height: 100%;
    padding: 14px 24px 0;
    overflow-y: scroll;
    word-wrap: break-word;
}

.widget_embed_code__code {
    font-family: monospace;
}
</style>
