<template>
    <portal to="popups_container">
        <div class="popup">
            <EventListener @keydown.prevent.escape="requestClose" />
            <EventListener @scroll.prevent="cancelScroll" />
            <div class="popup__content">
                <video v-if="previewIsVideo" class="video" autoplay loop>
                    <source :src="preview" type="video/mp4" />
                </video>
                <span v-else v-html="preview"></span>
            </div>
            <div class="popup__overlay" @click="requestClose"></div>
        </div>
    </portal>
</template>
<script>
import EventListener from "@/components/Global/Atoms/EventListener.vue";

export default {
    name: "Popup",
    components: {
        EventListener,
    },
    props: {
        preview: {
            type: String,
            required: true,
        },
        previewIsVideo: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        requestClose() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.popup__content {
    position: fixed;
    margin-top: 50vh;
    margin-left: 50vw;
    transform: translate3d(-50%, -50%, 0px);
    z-index: 21;
    background: $white;
    border: 1px solid $grey_mischka;
    border-radius: 4px;
    padding: 24px;
    overflow: auto;
}
.popup__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background: $white;
    opacity: 0.6;
}
.video {
    width: 550px;
}
</style>
