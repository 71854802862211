<template>
    <div class="widget_showcase">
        <div class="widget_showcase_header" v-if="showHeader">
            <div class="widget_showcase_header_first_line">
                <div class="headline6">{{ $t(widget.name) }}</div>

                <div
                    v-if="widget.type === 'Research'"
                    @click="customiseAnimatedWidget(widget)"
                    class="widget_showcase_header__customize"
                >
                    {{ $t("cp__widgets_preview__customize") }}
                </div>

                <router-link
                    v-if="widget.id === 'classic-widget'"
                    to="/widgets/classic/settings"
                    class="widget_showcase_header__customize"
                >
                    {{ $t("cp__widgets_preview__customize") }}
                </router-link>
            </div>
            <div class="widget_showcase_header__type">{{ widget.type }}</div>
        </div>

        <div
            v-if="!showEmbedCode && $t(widget.descriptionTranslationKey)"
            class="widget_showcase_header_subtitle"
        >
            {{ $t(widget.descriptionTranslationKey) }}
        </div>

        <div v-if="!showEmbedCode" class="widget_showcase_display_holder">
            <div class="widget_showcase_display" :data-widget-id="widget.id" @click="openFullSize">
                <video v-if="previewIsVideo" class="widget_showcase_video" autoplay loop>
                    <source :src="preview" type="video/mp4" />
                </video>
                <div v-else :class="{ widget_showcase_display_span: hasDynamicPreview }">
                    <span
                        class="widget_showcase_display_span"
                        :class="{ widget_showcase_display_dutch_span: language === 'nl' }"
                        v-html="preview"
                    ></span>
                </div>

                <div
                    v-if="enablePopup"
                    v-tippy
                    :content="$t('customer_portal_widgets_showcase_tooltip')"
                    class="overlay"
                />
            </div>
        </div>
        <WidgetEmbedCode
            ref="widgetEmbedCodeComp"
            v-else
            :id="widget.id"
            :embedCode="embedCode"
            @close="close"
            :auto-select="true"
        />
        <Button
            v-if="showCopyButton"
            buttonStyle="primary"
            class="widget_showcase_copy_button"
            :class="{ widget_showcase_copied_button: showEmbedCode }"
            @click.native="copyCode"
            :loading="widgetEmbedCodeIsLoading"
        >
            {{ $t(copyButtonLabel) }}
        </Button>
    </div>
</template>

<script>
import * as copy from "copy-to-clipboard";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import { mapGetters } from "vuex";
import generateWidget from "@/graphql/widgets/generateWidget.gql";
import WidgetEmbedCode from "@/components/Widgets/Atoms/WidgetEmbedCode.vue";

export default {
    name: "WidgetPreview",
    components: {
        Button,
        WidgetEmbedCode,
    },
    props: {
        widget: {
            type: Object,
            required: true,
        },
        showCopyButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true,
        },
        enablePopup: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            currentLanguage: "LanguageStore/getLanguage",
            language: "LanguageStore/getLanguage",
        }),
        previewIsVideo() {
            return this.widget.previewFilename.includes(".mp4");
        },
        preview() {
            if (this.isReviewWidget) {
                // eslint-disable-next-line
                return require(`@/assets/widget-previews/${this.widget.previewFilename}`);
            }
            return `<iframe
                        id="tv-widget-iframe-medium"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="${this.src}"
                        width="300"
                        height="400"
                    ></iframe>`;
        },
        src() {
            return `${
                this.widget.options.baseUrl
            }/cgi-bin/react_tool.pl?Op=Widget&template=reviews_${this.widget.id.split("-")[1]}&${
                this.resultScope
            }=${this.selectedScopeId}&width=300&height=$400&num_reviews=10`;
        },
        isReviewWidget() {
            return this.widget.type === "Review";
        },
        resultScope() {
            if (this.widget.options.scopeData) {
                switch (this.widget.options.scopeData.resultScope) {
                    case "holding":
                        return "holdingId";
                    case "organization":
                        return "organisatieID";
                    case "location":
                        return "vestigingID";
                    default:
                        return "";
                }
            }
            return "";
        },
        hasDynamicPreview() {
            return this.widget.options.scopeData !== null && this.widget.options.baseUrl !== null;
        },
        selectedScopeId() {
            const { scopeData } = this.widget.options;
            if (scopeData && scopeData.resultScope) {
                if (scopeData.resultScope === "holding" && scopeData.holding.id) {
                    return scopeData.holding.id;
                }
                if (scopeData.resultScope === "organization") {
                    if (scopeData.organisations.length > 1) {
                        return scopeData.organisations[0].id;
                    }
                    return scopeData.organisation.id;
                }
                if (scopeData.resultScope === "location") {
                    if (scopeData.locations.length > 0) {
                        return scopeData.locations[0].id;
                    }
                    return scopeData.location.id;
                }
            }
            return "";
        },
    },
    data() {
        return {
            showEmbedCode: false,
            embedCode: null,
            widgetEmbedCodeIsLoading: false,
            copyButtonLabel: "customer_portal_widgets_showcase_embed_code_copy",
        };
    },
    methods: {
        async copyCode() {
            await this.generateWidget();

            if (!this.embedCode) {
                return;
            }

            this.showEmbedCode = true;
            await copy(this.embedCode);
            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message"),
            });

            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copied";

            // Since sometimes the embedded code is the same (from the server)
            // and widgetEmbedCodeComp code selection depends on the code changes,
            // we need to trigger the selection manually in this specific case.
            this.$refs.widgetEmbedCodeComp.selectEmbedCode();
        },
        close() {
            this.showEmbedCode = false;
            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copy";
        },
        openFullSize() {
            if (this.enablePopup) {
                this.$emit("openFullSize", { preview: this.preview, isVideo: this.previewIsVideo });
            }
        },
        async generateWidget() {
            this.widgetEmbedCodeIsLoading = true;

            try {
                const input = {
                    type: this.widget.id.split("-")[0],
                    language: this.currentLanguage,
                    reviews: 0,
                };

                // Basically if it's not a research widget
                if (this.widget.options.scopeData === null) {
                    input.divisionUuid = this.activeAccount.externalUuid;
                    input.size = "mini";
                } else {
                    // Set default props for animated widgets
                    input.size = "medium";
                    input.resultScope = this.widget.options.scopeData.resultScope;
                    input.scopeValue = this.selectedScopeId;
                    input.width = 300;
                    input.height = 400;
                    input.limitLastYear = 0;
                    input.reviews = 10;
                    // eslint-disable-next-line prefer-destructuring
                    input.theme = this.widget.id.split("-")[1];
                }

                const { data } = await this.$apollo.mutate({
                    mutation: generateWidget,
                    variables: {
                        input,
                    },
                });

                // atob is the browser-native base64 decode operation
                this.embedCode = atob(data.generateWidget.embedCode);
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__pn__config_invitation__error__title"),
                    message: this.$t("cp__generic__error"),
                });
            } finally {
                this.widgetEmbedCodeIsLoading = false;
            }
        },
        customiseAnimatedWidget(widget) {
            this.$router.push({
                name: "CustomiseAnimatedWidget",
                params: { widget },
            });
        },
    },
};
</script>
<style lang="scss">
// This SCSS is not scoped, since these widgets are in a slot
[data-widget-id="main-2-reviews-small"] .__fbcw__widget {
    transform: scale(0.7) translateY(-18px) translateX(-2px) !important;
}

[data-widget-id="main-2-reviews"] .__fbcw__widget {
    transform: scale(0.78) translateY(-50px) translateX(-10px) !important;
}

[data-widget-id="sticky"] {
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.widget_showcase_display {
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;

    span {
        max-height: 100%;
        max-width: 100%;
        display: inline-block;
        height: auto;
    }
}

.widget_showcase {
    background: $white;
    border-radius: 8px;
    border: 1px solid $grey_alabaster;
    padding: 0 24px;
    height: 566px;
    width: 356px;
    display: flex;
    flex-direction: column;
}

.widget_showcase_header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey_alabaster;
    padding-bottom: 12px;
}

.widget_showcase_header_first_line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.widget_showcase_header__type {
    @extend %subtitle3_style;
    color: $grey_bombay;
}

.widget_showcase_header__customize {
    @extend %button_typography_style;
    color: $blue;
    cursor: pointer;
    margin-top: auto;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.widget_showcase_header_subtitle {
    @extend %subtitle2_style;
    padding-top: 8px;
}

.widget_showcase_copy_button {
    width: 100%;
    margin: 22px 0;
}

.widget_showcase_copied_button {
    margin-top: 8px;
    background-color: #285395 !important;
}

.widget_showcase_video {
    width: 320px;
    cursor: pointer;
}

.widget_showcase_display_holder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-shrink: 1;
    padding: 24px 24px 0;

    * {
        max-width: 100%;
        overflow: hidden;
    }
}

.widget_embed_code {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    border-radius: 12px;
    overflow: hidden;
    background-color: $grey_mineshaft;
    position: absolute;
}

.widget_embed_code__header {
    width: 100%;
    height: 24px;
    padding: 0 0 0 24px;
    background-color: $blue;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget_showcase_display_span {
    height: 300px;
}

.widget_showcase_display_span::v-deep #tv-widget-iframe-large {
    transform: scale(0.6) translateY(-170px) translateX(-80px);
}

.widget_showcase_display_span::v-deep #tv-widget-iframe-medium {
    transform: scale(0.76) translateY(-70px);
}

.widget_showcase_display_dutch_span::v-deep #tv-widget-iframe-large {
    transform: scale(0.55) translateY(-210px) translateX(-90px);
}

.widget_showcase_display_dutch_span::v-deep #tv-widget-iframe-medium {
    transform: scale(0.7) translateY(-90px);
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
}
</style>
