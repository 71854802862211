<script>
// From Rolf Haug, saw this at VueJS Amsterdam
// https://www.youtube.com/watch?v=4tw_ll_EYss
// https://github.com/vueschool/application-shortcuts/blob/master/src/components/EventListenerFinal.vue
const modifierRegEx = /[!~&]/g;
function extractOptions(event) {
    const modifierMap = {
        "!": "capture",
        "~": "once",
        "&": "passive",
    };
    const modifiers = event.match(modifierRegEx) || [];
    const options = Object.create(null);
    modifiers.forEach((modifier) => {
        const option = modifierMap[modifier];
        options[option] = true;
    });
    return options;
}
export default {
    render() {
        return null;
    },
    data() {
        return {
            wrappedHandler: null,
        };
    },
    methods: {
        executor() {
            const disallowedFocusTypes = ["INPUT", "TEXTAREA"];
            // eslint-disable-next-line no-undef
            if (disallowedFocusTypes.indexOf(document.activeElement.nodeName) > -1) return empty;
            // eslint-disable-next-line no-restricted-globals
            this.$listeners[event]();
        },
    },
    mounted() {
        Object.keys(this.$listeners).forEach((event) => {
            const handler = this.$listeners[event];
            // eslint-disable-next-line no-unused-expressions
            this.wrappedHandler = (op) => {
                const disallowedFocusTypes = ["INPUT", "TEXTAREA"];
                if (disallowedFocusTypes.indexOf(document.activeElement.nodeName) > -1) return {};
                handler(op);
            };
            window.addEventListener(
                event.replace(modifierRegEx, ""),
                this.wrappedHandler,
                extractOptions(event)
            );
        });
    },
    destroyed() {
        Object.keys(this.$listeners).forEach((event) => {
            // eslint-disable-next-line no-unused-vars
            const handler = this.$listeners[event];
            window.removeEventListener(event.replace(modifierRegEx, ""), this.wrappedHandler);
        });
    },
};
</script>
